import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import VModal from 'vue-js-modal'
import firebase from "firebase"
import 'firebase/auth'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyApRV9u9u0SBk8UyW1FzE3k1Es5aH1UVqA",
  authDomain: "mini-marketing-games.firebaseapp.com",
  projectId: "mini-marketing-games",
  storageBucket: "mini-marketing-games.appspot.com",
  messagingSenderId: "124403180776",
  appId: "1:124403180776:web:196cb707d622f2ac89d55d",
  measurementId: "G-1E349LRCMS"
}

firebase.initializeApp(firebaseConfig);
Vue.prototype.moment = moment
Vue.config.productionTip = false
Vue.use(VModal)
Vue.use(VueToast)
Vue.use(VueAxios, axios)

new Vue({
  render: h => h(App),
}).$mount('#app')
