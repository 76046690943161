<template>
  <div id="app" class="calendar-element">
    <Calendar/>
    <img class="back back-el-1" src="/neve.png">
    <img class="back back-el-2" src="/neve_2.png">
    <img class="back back-el-3" src="/pungitopo_1.png">
    <img class="back back-el-4" src="/pungitopo_2.png">
  </div>
</template>

<script>
import Calendar from './components/Calendar.vue'

export default {
  name: 'App',
  components: {
    Calendar
  }
}
</script>

<style>
  .back{
    opacity: 0.9;
  }
  .back-el-1{
    position: fixed;
    top: -95px;
    left:-30px;
    max-width:80%;
    z-index: 1;
  }
  .back-el-2{
    position: fixed;
    top: -95px;
    right:-150px;
    max-width:80%;
    z-index: 1;
  }
  .back-el-3{
    position: fixed;
    bottom: -20px;
    right:-10px;
    max-width:280px;
    z-index: 1;
  }
  .back-el-4{
    position: fixed;
    bottom: -20px;
    left:-10px;
    max-width:280px;
    z-index: 1;
  }
  @media (max-width: 600px) {
    .back-el-1 {
        position: fixed;
        top: -41px;
        left: -120px;
        max-width: 80%;
        z-index: 1;
    }
    .back-el-2{
      position: fixed;
      top: -35px;
      right:-160px;
      max-width:80%;
      z-index: 1;
    }
    .back-el-3{
      position: fixed;
      bottom: -20px;
      right:-10px;
      max-width:130px;
      z-index: 1;
    }
    .back-el-4{
      position: fixed;
      bottom: -20px;
      left:-10px;
      max-width:130px;
      z-index: 1;
    }
  }
</style>
