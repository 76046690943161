<template>
<div>
  <div class="container overall">
      <div class="logo">
        <img src="/logo_up.png" />
      </div>
      <div v-if="isChristmas" class="balls-cointainer flex-container">
        <div v-for="(el, i) in 24" class="ball flex-item" @click="(checkDay(el) == '') ? openModal() : closeModal()" :class="checkDay(el)" :key="i">
          <img src="/bar.png" class="bar"/>
          <div class="day-number">{{el}}</div>
        </div>
      </div>
      <div class="roboto col-white" v-else>
        <h1> Non è ancora dicembre! <br> Controlla la pagina a dicembre <br>e segui ogni giorno il nostro <br>calendario dell'avvento!</h1>
      </div>
      <div>
          <div class="logo-down">
            <img src="/logo_down.png" />
          </div>
          <p class="col-white read-docu">Operazione a premi valido dal 1 Dicembre al 24 Dicembre. Per informazioni consultare il <a href="/docs/regolamento-calendario-avvento-amysushi.pdf"> regolamento</a> e la <a href="/docs/terms_conditions_calendario_avvento_amysushi.pdf"> privacy policy & terms </a> o contattaci a info@amysushi.it</p>
      </div>
  </div>
  <modal @before-close="checkPoint" adaptive="true" class="roboto winner-modal" name="win">
        <div v-if="!loggedin && !toolate">
          <!-- <h2 v-if="!otpIsSent">Il premio di oggi è:</h2> -->
          <div v-if="yetplay"><h2>Hai già riscosso <br>il premio di oggi!</h2></div>
          <h2 v-if="otpIsSent && !yetplay">Conferma codice OTP</h2>
          <div v-if="!otpIsSent && !yetplay">
            <div>
              <img class="win-ball" v-if="yetregistered" :src="currentPrize.image">
              <div class="block-sized"></div>
              <!--<p class="text-gray" v-if="currentPrize.limited"> <small>Premi disponibili: {{this.availability}}</small> </p>-->
              <p class="" v-if="availability <= 0"> Ci spiace, per oggi sono finite le disponibilità dei premi!</p>
            </div>
            <div v-if="availability > 0">
              <label>Inserisci il tuo numero di cellulare <!--<small>(senza prefisso)</small>--><br>
                <input id="phonenumber"
                  maxlength="13"
                  required
                  v-model="phoneNumber"
                  type="number"
                  @change="getUser"
                >
              </label>
            </div>
            <small v-if="availability > 0" class="infosmall">invieremo un codice di verifica per abilitare il premio.</small>
          </div>
          <div v-if="availability > 0" style="display: flex; flex-direction: row;">
            <!--<p><img class="win-ball" v-if="yetregistered && otpIsSent" :src="currentPrize.image"><br></p>-->
            <OtpInput
              v-if="otpIsSent"
              ref="otpInput"
              input-classes="otp-input"
              class="otp"
              id="otpField"
              separator="-"
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="verifyCode"
            ></OtpInput>
          </div>
          <p v-if="otpIsSent" style="padding: 0px 20px; margin-top:15px;"><small> inserendo il codice di verifica accetti la <a href="/docs/terms_conditions_calendario_avvento_amysushi.pdf"> privacy policy & terms </a> inerente consultabile online. </small></p>
          <div v-if="availability > 0" class="flex mt-15">
            <div v-if="!otpIsSent && telephoneComplete && checkuser.length <= 0">
              <label>Inserisci la tua mail<br>
              <input
                v-model="email"
                type="email"
              >
            </label>
                  <br>
            </div>
            <div v-if="!otpIsSent && telephoneComplete && checkuser.length <= 0">
              <label>Nome e cognome<br>
              <input
                v-model="name"
                type="text"
              >
            </label>
                  <br>
            </div>
          </div>
          <div v-if="availability > 0">
            <small v-if="!otpIsSent" class="infosmall">Ricordati di inserire i dati corretti, altrimenti non potrai ritirare il tuo premio!</small>
            <br>
            <div v-if="!otpIsSent" id="recaptcha-container" style="background-color:#fff;width:300px;margin:auto;">
            </div>
            <br v-if="!yetplay">
            <button v-if="!otpIsSent && !yetplay && recaptchaWidgetId == null" @click="getUser(true)" class="btn-require">Richiedi il premio</button>
          <!-- <button v-else @click="verifyCode" class="btn-require mt-15">Conferma codice OTP</button> -->
          </div>
        </div>
        <div v-if="loggedin">
          <h2>Ritira il tuo premio</h2>
          <p>Riceverai anche via mail il qrcode del tuo premio.</p>
          <p><img class="qrvincita win-ball" :src="currentPrize.image"></p>
          <p>{{currentPrize.title}}<br v-if="currentPrize.description.trim() != ''">{{currentPrize.description}}</p>
          <button @click="downloadPdf" class="btn-require">Scarica il PDF</button>
        </div>
        <div v-if="toolate">
          <img height="350px" src="/too_late.png">
          <p class="remember">Ricordati che puoi giocare ogni giorno<br> dal <b>1 Dicembre al 24 Dicembre</b> fino alle ore <b>{{hourlimit}}</b> <br>oppure fino al raggiungimento del limite di vincite, se indicato.</p>
        </div>
      </modal>
</div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import firebase from "firebase";

export default {
  name: 'Calendar',
  components: {
    OtpInput
  },
  props: {
  },
  data () {
    return {
      name: '',
      toolate: false,
      currentPrize: {},
      win: {},
      otpIsSent: false,
      isChristmas: false,
      phoneNumber:null,
      confirmationResult:null,
      otpnum:null,
      recaptchaVerifier:null,
      recaptchaWidgetId:null,
      confirmResult:null,
      smsSent:false,
      loader: false,
      email: '',
      currentDay: 0,
      loggedin: false,
      yetplay: false,
      monthplay: 12,
      hourlimit: '15:00',
      checkuser: [],
      telephoneComplete: false,
      santa: false,
      availability: 1,
      yetregistered: false,
      test_mode: false,
      test_day: null,
    }
  },
  mounted () {
      const month = this.moment(new Date()).format("MM")
      this.currentDay = this.moment(new Date()).format("DD")
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if ({}.hasOwnProperty.call(params, 'test')) {
        if (params.test) {
          this.currentDay = params.day
        }
        if ({}.hasOwnProperty.call(params, 'day')) {
          if(params.day) {
            this.test_mode = true
            this.test_day = params.day
          }
        }
        if ({}.hasOwnProperty.call(params, 'hour')) {
          if(params.hour) {
            this.hourlimit = params.hour
          }
        }
        if ({}.hasOwnProperty.call(params, 'month')) {
          if(params.month) {
            this.monthplay = params.month
          }
        }
      }
      if (month == this.monthplay) {
        this.isChristmas = true
      }
      console.log("current day: " + this.currentDay)
      let x = window.localStorage.getItem("yetplay")
      if (x == this.currentDay) {
        this.yetplay = true
      }
      let tel = window.localStorage.getItem("telephone")
      if (tel != null) {
        this.phoneNumber = tel
        this.yetregistered = true
      }
      try {
        firebase.auth().useDeviceLanguage()
          this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('log-in',{
            'size':'invisible',
            'callback':(response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              console.log(response)
            }
        })
      } catch (e) {
        console.log("mmhh firebase problem")
      }
      let today = this.moment().format('HH:mm')
      let stophour = this.moment(this.hourlimit, 'HH:mm')
      this.toolate = this.moment(today, 'HH:mm') > stophour
      if (!this.toolate) {
        this.getCurrentPrize()
      }
      this.getVisited()
  },
  methods: {
    downloadPdf: function () {
      window.jsPDF = window.jspdf.jsPDF
      var doc = new jsPDF('p', 'pt', 'a4', false) // eslint-disable-line
      doc.setFontSize(19)
      doc.text(35, 50, 'PREMIO CALENDARIO AVVENTO AMY SUSHI')
      doc.setFontSize(15)
      doc.text(35, 100, 'Premio giorno ' + this.currentDay + ':')
      doc.text(35, 118, this.currentPrize.title + ' ' + this.currentPrize.description)
      doc.addImage(this.win.qrcode, 10, 125)
      doc.text(35, 520, 'Vincitore: ' + this.name)
      doc.text(35, 550, 'Email associata: ' + this.email)
      doc.setFontSize(9)
      doc.text(35, 600, 'Promo valida dal 1 al 24 dicembre, leggi il regolamento sul sito web.')
      doc.text(35, 628, 'Il qrcode sopra riportato è da mostrare in cassa. Non scansionare per non comprometterne la validità.')
      doc.save('CALENDARIO AVVENTO AMY SUSHI ' + this.currentDay + ' DICEMBRE.pdf')
    },
    getCurrentPrize: function (){
      var query_to_send = 'https://api.amysushi.it/avvento/prize/current'
      if (this.test_mode) {
        query_to_send = 'https://api.amysushi.it/avvento/prize/current/test'
      }
      //avoid get all prize calculating current prize of the day by the server!
      this.$http.get(query_to_send).then((response)=>{
        this.currentPrize = response.data[0]
        if (this.currentPrize.limited) {
          this.availability = this.currentPrize.limits - this.currentPrize.available
        } else {
          this.availability = 1
        }
      })
    },
    getVisited: function (){
      this.$http.put('https://api.amysushi.it/avvento')
    },
    getClicked: function (){
      this.$http.put('https://api.amysushi.it/avvento/click')
    },
    getUser: function (){
      if (this.name.trim() != '' && this.email.trim() != '' && this.phoneNumber.trim() != '') {
        this.sendOtp()
      } else {
        if (!this.loader) {
          this.$http.post(`https://api.amysushi.it/avvento/users/check`,  {telephone: this.phoneNumber}).then((response)=>{
            this.checkuser = response.data
            if (this.checkuser.length > 0) {
              this.yetregistered = true
              this.name = this.checkuser[0].name
              this.email = this.checkuser[0].email
              this.telephoneComplete = false
              this.sendOtp()
            } else {
               if (!this.telephoneComplete) {
                 this.$toast.clear()
                this.$toast.info('Non ti sei mai registrato! Completa la registrazione e apri il tuo premio!', {queue: true})
              }
              this.telephoneComplete = true
              this.email = ''
              this.name = ''
            }
          })
        } else {
          this.$toast.info('Inserisci tutti i dati e apri il tuo premio!')
        }
      }
    },
    registerForPrize: function (user){
      this.$http.post('https://api.amysushi.it/avvento/register', {name: this.name, telephone: this.phoneNumber, mail: this.email, uuid: user}).then((response)=>{
        if (response.data.success == 1) {
          this.$toast.success('Numero verificato correttamente!')
          this.loader = false
          this.win = response.data
          this.loggedin = true
          window.localStorage.setItem("yetplay", this.moment(new Date()).format("DD"))
          window.localStorage.setItem("telephone", this.phoneNumber)
        } else {
          this.$toast.error(response.data.msg)
          this.otpIsSent = false
          this.smsSent= false
        }
      }).catch(()=> {
          this.loader = false
          this.$toast.error('Impossibile verificare il numero o registrare la vincita.')
      })
      //this.loggedin = true
    },
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    verifyCode: function(otp)
    {
        this.otpnum = otp
        console.log(otp)
        this.loader = true
        this.confirmResult.confirm(this.otpnum)
        .then((result)=>{
          this.loader = false
          var user = result.user
          this.registerForPrize(user)
          console.log(user)
        })
        .catch((error)=>{
          console.log(error)
          this.loader = false
          this.$toast.error('OTP non corretto!')
        })
    },
    checkPoint: function (event) {
      if (this.otpIsSent) {
        event.cancel()
      }
    },
    sendOtp: function () {
      if (this.emailIsValid(this.email) && this.name.trim() != '') {
        if (this.phoneNumber != null) {
          if (this.phoneNumber === '3346417777') {
            this.santa = true
          } else {
            this.loader = true
            this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
            this.recaptchaVerifier.render().then((widgetId)=>{
              this.recaptchaWidgetId = widgetId
            })

            var number = '+39' + this.phoneNumber
            firebase.auth().signInWithPhoneNumber(number,this.recaptchaVerifier)
            .then((confirmationResult)=>{
                this.confirmResult = confirmationResult
                console.log(this.confirmResult)
                this.otpIsSent = true
                this.smsSent=true
                this.loader = false
                this.$toast.success('OTP inviato con successo, controlla il tuo cellulare.')
            })
            .catch((error)=>{
                this.loader = false
                this.$toast.error('Non ci è possibile inviare il codice OTP: ' + error.message)
                console.log("Sms not sent", error.message)
            })
          }
        } else {
          this.$toast.warning('Non hai inserito nessun numero di telefono.')
        }
      } else {
        this.$toast.warning("Attenzione, inserisci dei dati validi.")
      }
    },
    closeModal: function () {
      this.showModal = false
    },
    openModal: function () {
        if (!this.yetplay) {
          document.getElementById("musica").play()
          this.$modal.show('win')
          this.getClicked()
        } else {
          this.$toast.default("Hai già riscosso il premio di oggi.")
        }
    },
    checkDay: function (el) {
      var day = this.moment(new Date()).format("DD")
      if (this.test_mode) {
        day = this.test_day
      }
      //let day = d.day()
      if (el < day) {
        return 'opened'
      } else {
        if (el > day) {
          return 'disabled'
        }
      }
      return ''
    }
  }
}
</script>

<style>
.mt-15{
  margin-top: 15px;
}
.otp-input.error {
  border: 1px solid red !important;
}
.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp input.otp-input {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid gray;
    margin: 5px;
    text-align: center;
    font-size: 25px;
}
.otp {
    margin: 0 auto;
}
button.btn-require {
    padding: 10px 20px;
    background: rgb(101 223 157 / 16%);
    color: #279b5c;
    border: 1px solid #279b5c;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
}
button.btn-require:hover{
  background: #279b5c;
  color: white;
  transition: all .3s;
}
.winner-modal input {
    height: 30px;
    font-size: 15px;
    font-weight: normal;
    padding: 5px;
    width: calc(100% - 40px);
    max-width: 250px;
    margin: 5px;
    border: 1px solid #cacaca;
    border-radius: 50px;
}
.winner-modal .vm--modal {
  padding-bottom: 20px;
  border-radius: 10px;
}
.infosmall {
  color: gray;
  font-size: 11px;
}
.flex div {
    width: 50%;
}
.flex {
    display: flex;
}
input#phonenumber {
    max-width: 100%;
}
.win-ball{
  max-height: 400px;
}
div#recaptcha-container {
    margin-top: 10px!important;
}
input {
  text-align: center;
}
label {
    color: #b20005;
    font-size: 14px;
    font-weight: bold;
}
.remember{
  color: gray;
}
.qrvincita{
  max-width: 85%;
}
.text-gray{
  color:gray;
  margin-top: -5px;
}
.block-sized{
  height: 25px;
  width: 100%;
}
@media (max-width: 600px) {
  div#otpField div input {
    /* display: block!important; */
    margin: 0 auto!important;
    margin-left: 2px!important;
    margin-right: 2px!important;
    width: 30px;
    height: 30px;
    margin-top: 25px!important;
  }
  div#otpField span{
    display: none;
  }
}
@media (max-width: 350px) {
  img.win-ball {
    max-height: 235px;
  }
}
@media (min-width: 351px) and (max-width: 400px) {
  img.win-ball {
    max-height: 300px;
  }
}
</style>